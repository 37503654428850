import { getAnalytics, isSupported, logEvent, setUserProperties } from "firebase/analytics";
import { firebaseApp } from "./clientApp";

export const fbAnalytics = isSupported().then((yes) =>
  yes ? getAnalytics(firebaseApp) : null,
);

interface TaskProps {
  eventId?: string | undefined;
  teamId: string | undefined;
  taskId: string;
  partId: string;
}

export async function submitTask({
  taskId,
  partId,
  teamId,
  eventId = "submit_task",
}: TaskProps) {
  const analytics = await fbAnalytics;
  if (analytics) {
    logEvent(analytics, eventId, {
      taskId: taskId,
      partId: partId,
      teamId: teamId,
    });
  }
}

export async function registerUser(teamId: string) {
  const analytics = await fbAnalytics;
  if (analytics) {
    setUserProperties(analytics, { team_id: teamId });
  }
}
