import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { useRouter } from "next/navigation";

interface ErrorBoxProps {
  title: string;
  body: string;
}

export default function ErrorBox({ title, body }: ErrorBoxProps) {
  const router = useRouter();

  return (
    <Alert severity="error" sx={{ mb: 2 }}>
      <AlertTitle>{title}</AlertTitle>
      {body}
      <br />
      <Button onClick={() => router.refresh()}>Refresh the page.</Button>
    </Alert>
  );
}
