"use client";
"use strict";

import { ReactNode, useEffect } from "react";
import NotLoggedIn from "@/components/auth/NotLoggedIn";
import { firebaseApp } from "@/libs/firebase/clientApp";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import dynamic from "next/dynamic";
const ErrorBox = dynamic(() => import("@/components/general/ErrorBox"));

const auth = getAuth(firebaseApp);

interface RequireAuthWrapperProps {
  children?: ReactNode;
}

export default function RequireAuthWrapper({
  children,
}: Readonly<RequireAuthWrapperProps>): ReactNode {
  // This page requires authentication, check we have that
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (!loading) {
      const checkAuthentication = async () => {
        await new Promise((resolve) => setTimeout(resolve, 200));
      };
      checkAuthentication();
    }
  }, [user, loading]);

  if (error) {
    return <ErrorBox title="Auth Error" body={error.message} />;
  }

  if (!loading) {
    if (user) {
      return <>{children}</>;
    }
    return <NotLoggedIn />;
  }
  return null;
}
