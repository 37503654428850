/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use strict";
"use client";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "@/libs/firebase/clientApp";
import { taskConverter } from "./schema";
import { TasksSchema } from "@hacksday/libscore";
import { useSyncExternalStore } from "react";

let data: TasksSchema | undefined;

export const firestoreTasks = {
  getSnapshot: () => {
    return data;
  },

  subscribe: (callback: () => void) => {
    const unsubscribe = onSnapshot(
      doc(firestore, "tasks", "tasks").withConverter(taskConverter),
      (doc) => {
        if (doc.data()) {
          data = doc.data();
          callback();
        }
      }
    );
    return () => {
      data = undefined;
      unsubscribe();
    };
  },
};

export const useTasksFirestore = () => {
  const data = useSyncExternalStore(
    firestoreTasks.subscribe,
    firestoreTasks.getSnapshot,
    firestoreTasks.getSnapshot
  );
  return data;
};
