"use client";
"use strict";

import { ReactNode, useEffect } from "react";
import NotLoggedIn from "@/components/auth/NotLoggedIn";
import { useAuthState } from "react-firebase-hooks/auth";
import dynamic from "next/dynamic";
import { firebaseAuth } from "@/libs/firebase/clientApp";
import { registerUser } from "@/libs/firebase/analytics";
import { getProjectId } from "@/libs/teams/clientTeam";
const ErrorBox = dynamic(() => import("@/components/general/ErrorBox"));

interface RequireAuthWrapperProps {
  children?: ReactNode;
}

export default function RequireAuthWrapper({
  children,
}: Readonly<RequireAuthWrapperProps>): ReactNode {
  // This page requires authentication, check we have that
  const [user, loading, error] = useAuthState(firebaseAuth);

  useEffect(() => {
    async function register() {
      if (!loading && user) {
        const teamId = await getProjectId(user);
        registerUser(teamId);
      }
    }
    register();
  }, [loading, user]);

  if (error) {
    return <ErrorBox title="Auth Error" body={error.message} />;
  }

  if (!loading) {
    if (user) {
      return <>{children}</>;
    }
    return <NotLoggedIn />;
  }
  return null;
}
