/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
"use strict";
"use client";

import Alert from "@mui/material/Alert";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";

import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ErrorBox from "@/components/general/ErrorBox";
import GoogleSignInButton from "./GoogleSignInButton";
import { firebaseAuth } from "@/libs/firebase/clientApp";

export default function NotLoggedIn() {
  const [signInWithGoogle, _, loading, error] = useSignInWithGoogle(firebaseAuth);
  const [loginError, setLoginError] = useState<string | null>(null);

  useEffect(() => {
    // Install a proxying service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/auth-service-worker.js", {
        scope: "/",
      });
    }
  }, []);

  const handleSignIn = async () => {
    try {
      await signInWithGoogle();
      setLoginError(null);
    } catch (error) {
      setLoginError(`Login failed with error: ${error}`);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "lg",
        p: 2,
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {error && <ErrorBox title="An Error Occurred" body={error.message} />}
      {loginError && <ErrorBox title="An Error Occurred" body={loginError} />}
      <Alert severity="warning">Please login to view this page.</Alert>
      <GoogleSignInButton handleSignIn={handleSignIn} disabled={loading} />
    </Box>
  );
}
