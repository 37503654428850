import { celebrated } from "@/libs/scoring/serverScore";
import Snackbar from "@mui/material/Snackbar";
import JSConfetti from "js-confetti";
import { useEffect, useState } from "react";
import { Tasks } from "@/components/context/TaskContext";
import { LOSE_EMOJI, WIN_EMOJI } from "@hacksday/libscore";

export default function Celebrate() {
  const [loading, setLoading] = useState<boolean>(true);
  const [jsConfetti, setJsConfetti] = useState<JSConfetti>();
  const [open, setOpen] = useState<boolean>(false);
  const [oldScore, setOldScore] = useState<number>(0);
  const [scoreText, setScoreText] = useState<string>("");
  const { scores } = Tasks();

  useEffect(() => {
    async function process() {
      if (loading) {
        setJsConfetti(new JSConfetti());
        setLoading(false);
      }
      let c = false;
      if (scores) {
        if (scores.totals.old_total > scores.totals.total) {
          c = true;
          setOpen(true);
          setScoreText(
            `😭 Your score decreased by ${oldScore - scores.totals.total} points (total: ${scores.totals.total}) 😭`,
          );
          if (jsConfetti) {
            await jsConfetti.addConfetti({ emojis: LOSE_EMOJI });
          }
        } else if (scores.totals.old_total < scores.totals.total) {
          c = true;
          setOpen(true);
          setScoreText(
            `🎉 Your score increased by ${scores.totals.total - oldScore} points (total: ${scores.totals.total}) 🎉`,
          );
          if (jsConfetti) {
            await jsConfetti.addConfetti({ emojis: WIN_EMOJI });
          }
        }
        if (c) {
          setOldScore(scores.totals.old_total);
          celebrated();
        }
      }
    }
    process();
  }, [scores, loading, jsConfetti, oldScore]);

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        message={scoreText}
        autoHideDuration={3000}
      />
    </>
  );
}
