/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { User } from "firebase/auth";
import { firestore } from "@/libs/firebase/clientApp";
import { doc, getDoc, setDoc } from "firebase/firestore";

export async function getTeamName(user: User): Promise<string> {
  if (!user.email) {
    throw new Error("No email address");
  }
  try {
    const id = await getProjectId(user);
    const docRef = doc(firestore, "teams", id);
    const teamDoc = await getDoc(docRef);
    if (!teamDoc.exists) {
      return user.email;
    }
    const data = teamDoc.data();
    if (data && data["name"]) {
      return data.name;
    }
    return id;
  } catch {
    return user.email;
  }
}

export interface Team {
  name: string;
  email: string;
  id?: string;
}

export async function getTeam(user: User): Promise<Team> {
  if (!user.email) {
    throw new Error("Invalid user - no email");
  }
  try {
    const id = await getProjectId(user);

    const docRef = doc(firestore, "teams", id);
    const teamDoc = await getDoc(docRef);
    if (!teamDoc.exists) {
      throw new Error(`Team ID "${id}" not found`);
    }
    const data = teamDoc.data();
    if (data && data["name"]) {
      return {
        name: data["name"],
        email: data["email"],
      };
    }
  } catch (error) {
    console.error(error);
    throw new Error(`User "${user.email}" does not have a project linked.`);
  }
  throw new Error(`An unknown error occured`);
}

export async function getProjectId(user: User): Promise<string> {
  const idTokenResult = await user.getIdTokenResult(true);
  if (idTokenResult.claims["team_id"]) {
    return idTokenResult.claims["team_id"] as string;
  }
  throw new Error(
    `User "${user.email || user.uid}" does not have a team / project assocaited.`,
  );
}

export async function updateTeamName(name: string, user: User) {
  if (!user.uid || !user.email) {
    throw new Error("Invalid user");
  }
  try {
    const id = await getProjectId(user);
    const teamDocRef = doc(firestore, "teams", id);
    await setDoc(
      teamDocRef,
      {
        name: name,
      },
      {
        merge: true,
      },
    );
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update team name");
  }
}
